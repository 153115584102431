import {RouteComponentProps} from "react-router";

import {isEmpty} from "@pg-mono/nodash";

import {isServer} from "../../../app/read_rp_environment_variables";
import {InvestmentOfferCategory, InvestmentOfferSubCategory} from "../../constants/investment_offer_constants";
import {validateInvestmentOfferSubCategory} from "./validate_investment_offer_subcategory";

export type IInvestmentOfferRedirectsLinks = {
    category: (params: {category: InvestmentOfferCategory}) => string;
    subcategory: (params: {category: InvestmentOfferCategory; subcategory: InvestmentOfferSubCategory}) => string;
};
export const redirectClientToInvestmentOfferCategoryUrl = (
    newFilters: {category?: InvestmentOfferCategory | null; subcategory?: InvestmentOfferSubCategory | null},
    oldFilters: {category?: InvestmentOfferCategory | null; subcategory?: InvestmentOfferSubCategory | null},
    history: RouteComponentProps["history"],
    links: IInvestmentOfferRedirectsLinks
) => {
    // this should not be used server side
    if (isServer) {
        return;
    }

    if (newFilters.category && newFilters.category !== oldFilters.category) {
        if (newFilters.subcategory && newFilters.subcategory !== oldFilters.subcategory) {
            const newPath = links.subcategory({
                category: newFilters.category,
                subcategory: newFilters.subcategory
            });
            return history.push(newPath);
        }
        if (oldFilters.subcategory) {
            const params = {
                category: newFilters.category,
                subcategory: oldFilters.subcategory
            };
            const isSubcategoryValid = validateInvestmentOfferSubCategory(params);
            const newCategoryPath = isSubcategoryValid ? links.subcategory(params) : links.category(params);
            return history.push(newCategoryPath);
        }
        const newPath = links.category({category: newFilters.category});
        return history.push(newPath);
    }

    if (newFilters.subcategory) {
        if (oldFilters.category && newFilters.subcategory !== oldFilters.subcategory) {
            const newSubCategoryPath = links.subcategory({
                category: oldFilters.category,
                subcategory: newFilters.subcategory
            });
            return history.push(newSubCategoryPath);
        }
    }

    if (isEmpty(newFilters.subcategory) && oldFilters.category) {
        history.push(links.category({category: oldFilters.category}));
    }
};
