import {rpAppLink} from "@pg-mono/rp-routes";

import {Country} from "../../region/types/Country";
import {InvestmentOfferCategory, investmentOfferCountries, InvestmentOfferSubCategory, InvestmentOfferTypeSlug} from "../constants/investment_offer_constants";

export const getAbroadOfferRedirectLink = (country: Country) => {
    const subcategory = investmentOfferCountries.find((type) => type.country === country);

    if (subcategory) {
        return rpAppLink.investmentOffer.investmentCategory.subcategory.base({category: InvestmentOfferTypeSlug.ABROAD, subcategory: subcategory.slug});
    }

    return rpAppLink.investmentOffer.investmentCategory.base({category: InvestmentOfferTypeSlug.ABROAD});
};

export const getInvestmentOfferListLink = (params: {category: InvestmentOfferCategory | null; subcategory: InvestmentOfferSubCategory | null}) => {
    if (params.category && params.subcategory) {
        return rpAppLink.investmentOffer.investmentCategory.subcategory.base({category: params.category, subcategory: params.subcategory});
    }

    return params.category && rpAppLink.investmentOffer.investmentCategory.base({category: params.category});
};
export const getInvestmentOfferListMapLink = (params: {category: InvestmentOfferCategory | null; subcategory: InvestmentOfferSubCategory | null}) => {
    if (params.category && params.subcategory) {
        return rpAppLink.investmentOffer.investmentCategory.subcategory.map({category: params.category, subcategory: params.subcategory});
    }

    return params.category && rpAppLink.investmentOffer.investmentCategory.map({category: params.category});
};
