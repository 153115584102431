import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {catch400, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiLink} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IPlannerPropertyRoomExposureSide} from "../types/planner_property/IPlannerPropertyRoomExposureSide";
import {IPlannerPropertyRoomType} from "../types/planner_property/IPlannerPropertyRoomType";

const FETCH_PLANNER_ESTATE_DETAILS_PREFIX = "planner/FETCH_ESTATE_DETAILS";
export const fetchPlannerEstateDetailsTypes = createRequestActionTypes(FETCH_PLANNER_ESTATE_DETAILS_PREFIX);

export interface IPlannerEstateDetails {
    is_middle?: boolean | null;
    rooms: {
        name: string;
        name_pl: string;
        exposure: IPlannerPropertyRoomExposureSide[] | null;
        room_type: IPlannerPropertyRoomType;
        sunlight_percentage: number | null;
    }[];
}

export const fetchPropertyPlannerEstateDetails = (ctx: Pick<IFetchContext<IRPRequestMeta>, "meta">) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    dispatch({type: fetchPlannerEstateDetailsTypes.start});

    const propertyUuid = getState().property.property?.uuid;

    if (!propertyUuid) {
        return true;
    }

    const planApiLink = apiLink.planner.estate.detail({})({propertyUuid});

    return getRequest(ctx.meta, planApiLink)
        .then((res: IPlannerEstateDetails) => {
            dispatch({type: fetchPlannerEstateDetailsTypes.success, result: res});
            return res;
        })
        .catch(
            catch400((error) => {
                dispatch({type: fetchPlannerEstateDetailsTypes.error, error: error.appError});
            })
        )
        .catch(
            catch404((error) => {
                dispatch({type: fetchPlannerEstateDetailsTypes.error, error});
            })
        );
};
