import React, {FC} from "react";
import {css, SerializedStyles, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {Text} from "@pg-design/text";

import {BadgeVariants, BadgeVariantsUnionType} from "../utils/badge_variant";

interface IProps {
    children: React.ReactNode;
    as?: React.ElementType;
    variant?: BadgeVariantsUnionType;
    icon?: (props: {fill: string; css: SerializedStyles}) => React.ReactElement;
    className?: string;
}

export const Badge: FC<IProps> = (props) => {
    const {as, variant = BadgeVariants.LABEL_DEFAULT, icon: Icon, className} = props;

    const Component = as || "span";

    const variantStyle = getBadgeVariantStyle(variant);

    return (
        <BadgeBase className={className} as={Component} css={variantStyle}>
            {Icon && <Icon fill="inherit" css={icon} />}
            <Text variant="info_txt_2" noWrap as={as}>
                {props.children}
            </Text>
        </BadgeBase>
    );
};

export const BadgeBase = styled.div`
    border-radius: 1.6rem;
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 0.9rem 0.5rem 1.04rem;
`;

const icon = css`
    margin-right: 1.04rem;
`;

const getBadgeVariantStyle = (variant: BadgeVariantsUnionType) => (theme: Theme) => {
    switch (variant) {
        case BadgeVariants.LABEL_DEFAULT: {
            return css`
                background-color: ${theme.colors.background?.[100]};
            `;
        }
        case BadgeVariants.LABEL_BG2: {
            return css`
                background-color: ${theme.colors.background?.[200]};
            `;
        }

        case BadgeVariants.LABEL_SUCCESS: {
            return css`
                background-color: ${theme.colors.success};
            `;
        }

        case BadgeVariants.LABEL_INFO: {
            return css`
                background-color: ${theme.colors.info};
            `;
        }

        case BadgeVariants.LABEL_WARNING: {
            return css`
                background-color: ${theme.colors.warning};
            `;
        }

        case BadgeVariants.LABEL_HIGHLIGHT: {
            return css`
                background-color: ${theme.colors.highlight};
            `;
        }

        case BadgeVariants.LABEL_PRIMARY: {
            return css`
                background-color: ${theme.colors.primary};
            `;
        }

        case BadgeVariants.LABEL_SECONDARY: {
            return css`
                background-color: ${theme.colors.secondary};
                color: white;
                fill: white;
            `;
        }

        case BadgeVariants.LABEL_DANGER: {
            return css`
                background-color: ${theme.colors.danger};
                color: white;
                fill: white;
            `;
        }
    }
};
